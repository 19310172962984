<template>
  <v-container class="bg-glass-effect-light" style="height: 100vh"></v-container>
</template>

<script>
import EuProtegidoService from '../../services/euprotegido/EuProtegidoService';
import {mapMutations} from "vuex";
export default {
  name: "screening",
  data: () => ({
    redirect: {
      name: 'survey' ,
      query: { id: 0, finalRoute: 'finalizarTriagem' }
    }
  }),
  async mounted () {
    this.loading(true)
    this._euProtegidoService = new EuProtegidoService();
    await this._euProtegidoService.GetScreeningSurveyIdByFinancialGroup()
        .then(response => {
          this.redirect.query.id = response.data
          this.$router.push(this.redirect)
        }).catch(() => {
          this.showmsg({ text: "Erro ao carregar os dados para redirecionamento da Triagem.", type: "error" });
        })
    this.loading(false)
  },
  methods: {
    ...mapMutations(["showmsg", "loading"])
  }
}
</script>


